import styled, { type Colors, css } from 'styled-components';
import Image, { type StaticImageData } from 'next/legacy/image';

import { QuoteMarketing } from '@yoweb/ui/components/typography';
import { th } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { QuoteIcon } from '@yoweb/ui/components/Icon/QuoteIcon';
import { isString } from '@yoweb/utils/isString';

export type CardRevealColor = keyof Pick<
  Colors,
  'coral' | 'evening' | 'morning' | 'citrus' | 'sky' | 'sprout'
>;

export type CardRevealProps = {
  image: StaticImageData | string;
  imageAltText: string;
  text: string;
  isRevealed: boolean;
  bgColor?: CardRevealColor;
};

/**
 * Card used in the dream gallery section of the home page.
 */
export const CardReveal = ({
  image,
  imageAltText,
  text,
  isRevealed,
  bgColor = 'evening',
}: CardRevealProps) => (
  <Card>
    <ImageWrapper>
      <Image
        src={image}
        layout="fill"
        objectFit="cover"
        alt={imageAltText}
        {...(!isString(image)
          ? { placeholder: 'blur' }
          : { placeholder: 'blur', blurDataURL: `${image}&blur=2000` })}
      />
    </ImageWrapper>
    <CardContent bgColor={bgColor} isRevealed={isRevealed}>
      <div>
        <QuoteIcon />
      </div>
      <QuoteMarketing size={{ _: 'lg', md: 'xl' }}>{text}</QuoteMarketing>
    </CardContent>
  </Card>
);

const fillParent = css`
  position: absolute;
  inset: 0;
`;

const Card = styled.article`
  background: ${th.getColor('base200')};
  border-radius: ${th.getRadii('small')};
  height: ${pxToRem(280)};
  position: relative;
  overflow: hidden;
  width: ${pxToRem(200)};

  /**
   * Need to force the card to a new composite layer to ensure correct rendering on Webkit.
   */
  transform: translateZ(0);

  ${media.md`
    height: ${pxToRem(344)};
    width: ${pxToRem(248)};
  `}
`;

const CardContent = styled.div<{ bgColor: CardRevealColor; isRevealed: boolean }>`
  background-color: ${({ bgColor }) => th.getColor(bgColor)};
  display: grid;
  grid-gap: ${th.getSpace('normal1')};
  align-content: start;
  opacity: 0;
  padding: ${th.getSpace('normal2')};
  transition:
    opacity ${th.getDuration('interaction')}ms,
    visibility ${th.getDuration('interaction')}ms ${th.getDuration('interaction')}ms;
  visibility: hidden;
  z-index: ${th.getZIndex('layer')};

  ${({ isRevealed }) =>
    isRevealed &&
    css`
      opacity: 1;
      visibility: visible;
      transition-delay: 0ms;
    `}

  ${fillParent}
`;

const ImageWrapper = styled.div`
  ${fillParent}
`;
