import { useState } from 'react';
import styled from 'styled-components';

import { useInView, StaggerElements } from '@yoweb/ui/components/AnimateIn';
import { Title } from '@yoweb/ui/components/typography';
import { th } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { type ActiveIndex, Direction, type LanesData } from './DreamGallery.utils';
import { DreamGalleryLane } from './DreamGalleryLane';
import { Box } from '@yoweb/ui/components/Box';

type DreamGalleryProps = {
  title: string | ReactNode;
  subtitle?: string;
  displayTextAsTitle?: boolean;
  rows: LanesData;
} & DataTestId;

/**
 * Dream gallery section of the home page.
 */
export const DreamGallery = ({
  rows,
  title,
  subtitle,
  displayTextAsTitle,
  'data-testid': dataTestId,
}: DreamGalleryProps) => {
  const [active, setActive] = useState<ActiveIndex>(null);
  const [ref, isInView] = useInView({ rootMargin: '-100px 0px' });

  return (
    <SectionWrapper data-testid={dataTestId}>
      <Box mx="large1">
        <DreamGalleryTextWrapper>
          <StaggerElements isInView={isInView}>
            {typeof title === 'string' ? (
              <Title
                textAlign="center"
                size={{ _: 'md', md: 'xl' }}
                maxWidth={664}
                whiteSpace="pre-wrap"
              >
                {title}
              </Title>
            ) : (
              title
            )}
            <Title textAlign="center" size={{ _: 'xs', md: 'sm' }} weight="regular">
              {subtitle}
            </Title>
          </StaggerElements>
        </DreamGalleryTextWrapper>
      </Box>
      <GalleryCards ref={ref}>
        {rows.map((row, index) => (
          <DreamGalleryLane
            key={index}
            row={index}
            direction={index % 2 ? Direction.RightToLeft : Direction.LeftToRight}
            items={row}
            isInView={isInView}
            active={active}
            setActive={setActive}
            displayTextAsTitle={displayTextAsTitle}
          />
        ))}
      </GalleryCards>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  display: grid;
  padding-top: ${pxToRem(110)};

  ${media.md`
    padding-top: ${th.getSpace('xl3')};
  `}
`;

const DreamGalleryTextWrapper = styled.div`
  display: grid;
  grid-gap: ${th.getSpace('normal3')};
  margin: 0 auto;
  justify-items: center;

  ${media.md`
    max-width: ${pxToRem(664)};
  `}
`;

const GalleryCards = styled.div`
  display: grid;
  margin-top: ${th.getSpace('medium1')};

  ${media.md`
    margin-top: ${th.getSpace('medium2')};
  `}
`;
