import type { RichTextField, ImageField, FilledLinkToWebField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const HeroSectionSliceType = 'hero_section_slice';

const commonRequiredPrimaryFields = ['title', 'subtitle'] as const;

// Default variant required fields
export const requiredPrimaryFields = [...commonRequiredPrimaryFields] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

// With video url variant required fields
export const requiredPrimaryFieldsWithVideoUrl = [
  ...commonRequiredPrimaryFields,
  'videoUrl',
  'ctaLabel',
] as const; // Array of all required primary fields.
export const requiredItemsFieldsWithVideoUrl = [] as const; // Array of all required items fields.

// With two buttons variant required fields
export const requiredPrimaryFieldsWithTwoButtons = [
  ...commonRequiredPrimaryFields,
  'secondaryBtnLabel',
] as const; // Array of all required primary fields.
export const requiredItemsFieldsWithTwoButtons = [] as const; // Array of all required items fields.

type CommonPrimary = {
  title: RichTextField;
  subtitle: RichTextField;
  ctaLabel: RichTextField;
  primaryBtnLabel?: RichTextField;
  desktopImage: ImageField;
  mobileImage: ImageField;
};

type Item = {
  desktopImage: ImageField;
  mobileImage: ImageField;
};

type Primary = {
  primaryBtnUrl: FilledLinkToWebField;
} & CommonPrimary;

type PrimaryWithVideoUrl = {
  videoUrl: FilledLinkToWebField;
  ctaLabel: RichTextField;
} & CommonPrimary;

type PrimaryWithTwoButtons = {
  secondaryBtnLabel: RichTextField;
  disclaimer?: RichTextField;
} & Primary;

export type DefaultSliceResponse = PrismicSlice<
  typeof HeroSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;

export type WithVideoModalSliceResponse = PrismicSlice<
  typeof HeroSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithVideoUrl>, PrimaryWithVideoUrl>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>,
  'withVideoModal'
>;

export type WithVideoModalAndCardSliceResponse = PrismicSlice<
  typeof HeroSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFieldsWithVideoUrl>, PrimaryWithVideoUrl>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>,
  'withVideoModalAndCard'
>;

export type WithTwoButtonsSliceResponse = PrismicSlice<
  typeof HeroSectionSliceType,
  CreateRequiredFields<
    ArrayToUnion<typeof requiredPrimaryFieldsWithTwoButtons>,
    PrimaryWithTwoButtons
  >,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>,
  'withTwoButtons'
>;

export type HeroSectionSliceResponse =
  | DefaultSliceResponse
  | WithVideoModalSliceResponse
  | WithTwoButtonsSliceResponse
  | WithVideoModalAndCardSliceResponse;
