/**
 * Splits array into chunks
 * You can either specify the chunk size by a number or an of array of sequence numbers
 * @example
 * splitArrayToChunks([1,2,3,4,5,6,7,8,9], 3) = [[1,2,3],[4,5,6],[7,8,9]]
 * splitArrayToChunks([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15], [3,4]) = [[1,2,3],[4,5,6,7],[8,9,10],[11,12,13,14],[15]]
 */
export const splitArrayToChunks = <T>(arr: T[], chunk: number | number[]) => {
  const newArr = [];

  if (Array.isArray(chunk)) {
    let arrStart = 0;
    let chunkIndex = 0;

    for (let i = 0; i < arr.length; i += chunk[chunkIndex]) {
      if (chunk[chunkIndex] + arrStart <= arr.length) {
        newArr.push(arr.slice(arrStart, chunk[chunkIndex] + arrStart));
      }

      arrStart = chunk[chunkIndex] + arrStart;

      if (chunk[chunkIndex + 1]) {
        chunkIndex++;
      } else {
        chunkIndex = 0;
      }
    }
  } else {
    for (let i = 0; i < arr.length; i += chunk) {
      newArr.push(arr.slice(i, i + chunk));
    }
  }

  return newArr;
};
