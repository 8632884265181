import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const DreamGallerySliceType = 'dream_gallery_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title'] as const; // Array of all required primary fields.
export const requiredItemsFields = ['image', 'text', 'isMobileImage'] as const; // Array of all required items fields.

type Primary = {
  title: RichTextField;
  subtitle: RichTextField;
  displayTextAsTitle: boolean;
  navId?: string;
};

type Item = {
  image: ImageField;
  text: string;
  isMobileImage: boolean;
};

export type DreamGallerySliceResponse = PrismicSlice<
  typeof DreamGallerySliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;
