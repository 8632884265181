import * as prismicHelpers from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import { useMemo } from 'react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import { HeroSection } from '@yoweb/ui/components/sections/HeroSection';
import type { HeroProps } from '@yoweb/ui/components/sections/HeroSection/HeroSection';
import {
  type HeroSectionSliceResponse,
  requiredPrimaryFields,
  requiredPrimaryFieldsWithVideoUrl,
  requiredPrimaryFieldsWithTwoButtons,
} from './HeroSectionSliceTypes';
import { smallPrintSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type HeroSectionSliceProps = {
  slice: HeroSectionSliceResponse;
  ctaUrlHash?: string;
} & Omit<
  HeroProps,
  'desktopImage' | 'mobileImage' | 'title' | 'subtitle' | 'primaryBtnLabel' | 'primaryBtnUrl'
>;

const HeroSectionSlice = ({ slice, ctaUrlHash, ...rest }: HeroSectionSliceProps) => {
  const [imageDesktop, imageMobile] = useMemo(() => {
    const imagePool = slice.items.map((item) => ({
      desktopImage: item?.desktopImage?.url,
      mobileImage: item?.mobileImage?.url,
    }));
    const randomIndex = Math.floor(Math.random() * imagePool.length);
    const desktopImage = slice.primary.desktopImage?.url ?? imagePool[randomIndex]?.desktopImage;
    const mobileImage = slice.primary.mobileImage?.url ?? imagePool[randomIndex]?.mobileImage;
    return [desktopImage, mobileImage];
  }, [slice.items, slice.primary.desktopImage, slice.primary.mobileImage]);

  const { title, subtitle, ctaLabel } = slice.primary;

  const commonProps = {
    desktopImage: imageDesktop ?? '',
    mobileImage: imageMobile ?? '',
    title: prismicHelpers.asText(title, '\n') ?? '',
    subtitle: prismicHelpers.asText(subtitle) ?? '',
    primaryBtnLabel: ctaLabel ? prismicHelpers.asText(ctaLabel) : '',
  };

  if (slice.variation === 'withVideoModal') {
    return (
      <HeroSection
        {...commonProps}
        {...rest}
        primaryBtnLabel={prismicHelpers.asText(slice.primary.ctaLabel)}
        videoUrl={slice.primary.videoUrl?.url}
      />
    );
  }

  if (slice.variation === 'withVideoModalAndCard') {
    return (
      <HeroSection
        {...commonProps}
        {...rest}
        primaryBtnLabel={prismicHelpers.asText(slice.primary.ctaLabel)}
        videoUrl={slice.primary.videoUrl?.url}
        withCard
      />
    );
  }

  if (slice.variation === 'withTwoButtons') {
    return (
      <HeroSection
        {...commonProps}
        {...rest}
        primaryBtnUrl={slice.primary.primaryBtnUrl?.url ?? ctaUrlHash ?? ''}
        secondaryBtnLabel={prismicHelpers.asText(slice.primary.secondaryBtnLabel)}
        disclaimer={
          slice.primary?.disclaimer ? (
            <PrismicRichText field={slice.primary.disclaimer} components={smallPrintSerializer} />
          ) : (
            ''
          )
        }
      />
    );
  }

  return (
    <HeroSection
      {...commonProps}
      {...rest}
      primaryBtnUrl={slice.primary.primaryBtnUrl?.url ?? ctaUrlHash ?? ''}
    />
  );
};

export default withPrismicGuardian(HeroSectionSlice, {
  type: 'variations',
  variations: {
    'default-slice': {
      primaryRequired: requiredPrimaryFields,
    },
    withVideoModal: {
      primaryRequired: requiredPrimaryFieldsWithVideoUrl,
    },
    withTwoButtons: {
      primaryRequired: requiredPrimaryFieldsWithTwoButtons,
    },
    withVideoModalAndCard: {
      primaryRequired: requiredPrimaryFieldsWithVideoUrl,
    },
  },
});
