import type {
  CardRevealProps,
  CardRevealColor,
} from '@yoweb/ui/components/marketing/DreamGallery/CardReveal';

export enum Direction {
  LeftToRight,
  RightToLeft,
}

export type ActiveIndex = null | string;
export type Card = Omit<CardRevealProps, 'isRevealed'>;
export type LanesData = readonly Card[][];

export const colorPattern: CardRevealColor[] = [
  'coral',
  'evening',
  'morning',
  'citrus',
  'sky',
  'sprout',
];
