import * as prismicHelpers from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type DreamGallerySliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './DreamGallerySliceTypes';
import { DreamGallery } from '@yoweb/ui/components/marketing/DreamGallery';
import { splitArrayToChunks } from '@yoweb/utils/splitArrayToChunks';
import { colorPattern } from '@yoweb/ui/components/marketing/DreamGallery/DreamGallery.utils';
import { dreamGallerySerializer } from '@yoweb/prismic-slicemachine/utils/serializers';
import { RenderOn } from '@yoweb/ui/components/RenderOn/RenderOn';

const formatRows = ({ image, text }: DreamGallerySliceResponse['items'][0], rowIndex: number) => ({
  bgColor: colorPattern[rowIndex % colorPattern.length],
  image: image.url ?? '',
  imageAltText: image.alt ?? '',
  text,
});

type DreamGallerySliceProps = {
  slice: DreamGallerySliceResponse;
};

const DreamGallerySlice = ({ slice }: DreamGallerySliceProps) => {
  const { title, subtitle, displayTextAsTitle, navId } = slice.primary;
  const mobileImages = slice.items.filter(({ isMobileImage }) => isMobileImage);

  const rows = Object.seal({
    mobile: splitArrayToChunks(mobileImages, [6, 7]).map((chunk) => chunk.map(formatRows)),
    desktop: splitArrayToChunks(slice.items, slice.items.length / 2).map((chunk) =>
      chunk.map(formatRows),
    ),
  });

  return (
    <div id={navId}>
      <RenderOn.MobileOnly>
        <DreamGallery
          data-testid="dream-gallery-mobile"
          title={title ? <PrismicRichText field={title} components={dreamGallerySerializer} /> : ''}
          subtitle={subtitle ? prismicHelpers.asText(subtitle) || '' : undefined}
          displayTextAsTitle={displayTextAsTitle}
          rows={rows.mobile}
        />
      </RenderOn.MobileOnly>
      <RenderOn.Tablet>
        <DreamGallery
          data-testid="dream-gallery-desktop"
          title={title ? <PrismicRichText field={title} components={dreamGallerySerializer} /> : ''}
          subtitle={subtitle ? prismicHelpers.asText(subtitle) || '' : undefined}
          displayTextAsTitle={displayTextAsTitle}
          rows={rows.desktop}
        />
      </RenderOn.Tablet>
    </div>
  );
};

export default withPrismicGuardian(DreamGallerySlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
