import styled, { css } from 'styled-components';
import Image, { type ImageProps, type StaticImageData } from 'next/legacy/image';
import type { ReactNode, MouseEvent } from 'react';

import { th } from '@yoweb/ui/styles/utils/theme';
import { Container } from '@yoweb/ui/components/containers';
import HeroCopy, { type MaxWidthProps } from './HeroCopy';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { ScaleIn } from '@yoweb/ui/components/AnimateIn';
import { isString } from '@yoweb/utils/isString';

const HEIGHT = 1080;
const WIDTH = 1920;

export type HeroProps = {
  primaryBtnClickHandler?: (e: MouseEvent) => void;
  secondaryBtnClickHandler?: (e: MouseEvent) => void;
  contentMaxWidth?: MaxWidthProps['maxWidth'];
  primaryBtnLabel?: string | ReactNode;
  primaryBtnUrl?: string;
  secondaryBtnLabel?: string | ReactNode;
  secondaryBtnUrl?: string;
  desktopImage: StaticImageData | string;
  desktopPosition?: string;
  disclaimer?: string | ReactNode;
  imageAltText?: string;
  mobileImage: StaticImageData | string;
  mobilePosition?: string;
  useMobileProps?: boolean;
  mobileImageHeight?: number;
  subtitle: string;
  title: string;
  videoUrl?: string;
  withCard?: boolean;
  ignoreMobileImageMaxHeight?: boolean;
} & DataTestId;

/**
 * @param ignoreMobileImageMaxHeight: certain images are taller than they are wide and setting a max height
 *                                    for them created dead space on their side.
 *                                    When this prop is true, it removes the imposed max height on the image
 *                                    and resolves the dead space issue without creating more sizing issues anywhere else.
 */

/**
 * Hero section with Fullscreen image (used on Homepages for Store and Pro).
 */
const HeroSection = ({
  desktopImage,
  desktopPosition = 'center',
  mobileImage,
  mobilePosition = 'center',
  useMobileProps,
  mobileImageHeight,
  imageAltText,
  title,
  subtitle,
  disclaimer,
  primaryBtnLabel,
  primaryBtnUrl,
  secondaryBtnUrl,
  secondaryBtnLabel,
  videoUrl,
  withCard,
  contentMaxWidth,
  primaryBtnClickHandler,
  secondaryBtnClickHandler,
  ignoreMobileImageMaxHeight,
}: HeroProps) => {
  const commonImageProps: Partial<ImageProps> = {
    priority: true,
    layout: 'fill',
    alt: imageAltText,
  };

  const desktopImageProps: Partial<ImageProps> = {
    objectFit: 'cover',
    ...commonImageProps,
    ...(isString(desktopImage)
      ? {
          placeholder: 'blur',
          blurDataURL: `${desktopImage}&blur=2000`,
        }
      : { placeholder: 'blur' }),
  };
  const mobileImageProps: Partial<ImageProps> = {
    objectFit: 'contain',
    ...commonImageProps,
    ...(isString(mobileImage)
      ? {
          placeholder: 'blur',
          blurDataURL: `${mobileImage}&blur=2000`,
        }
      : { placeholder: 'blur' }),
  };

  return (
    <ScaleIn isVisible>
      <HeroContainer data-testid="hero-container" height={mobileImageHeight}>
        <HeroWrapper $ignoreMobileImageMaxHeight={ignoreMobileImageMaxHeight}>
          <DesktopImage>
            {desktopImage && (
              <Image
                src={desktopImage}
                objectPosition={desktopPosition}
                {...(!isString(desktopImage) ? { placeholder: 'blur' } : {})}
                {...desktopImageProps}
              />
            )}
          </DesktopImage>
          <MobileImage>
            {mobileImage && (
              <Image
                src={mobileImage}
                objectPosition={mobilePosition}
                {...(!isString(mobileImage) ? { placeholder: 'blur' } : {})}
                {...(useMobileProps ? mobileImageProps : desktopImageProps)}
              />
            )}
          </MobileImage>
        </HeroWrapper>
      </HeroContainer>
      {title && (
        <Container>
          <Content withCard={withCard}>
            <HeroCopy
              title={title}
              description={subtitle}
              disclaimer={disclaimer}
              primaryBtnLabel={primaryBtnLabel}
              primaryBtnClickHandler={primaryBtnClickHandler}
              primaryBtnUrl={primaryBtnUrl}
              secondaryBtnLabel={secondaryBtnLabel}
              secondaryBtnClickHandler={secondaryBtnClickHandler}
              secondaryBtnUrl={secondaryBtnUrl}
              videoUrl={videoUrl}
              withCard={withCard}
              maxWidth={contentMaxWidth}
            />
          </Content>
        </Container>
      )}
    </ScaleIn>
  );
};

const HeroContainer = styled.div<{ height?: number }>`
  background: ${th.getColor('base100')};
  height: calc(${WIDTH / HEIGHT} * 100vh);
  overflow: hidden;
  position: relative;
  max-height: 62vh;

  ${({ height }) =>
    height &&
    css`
      height: ${pxToRem(height)};
    `}

  ${media.lg`
    height: 100vh;
    max-height: 100vh;
  `}
`;

const HeroWrapper = styled.div<{ $ignoreMobileImageMaxHeight?: boolean }>`
  height: calc(${WIDTH / HEIGHT} * 100vh);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${({ $ignoreMobileImageMaxHeight }) =>
    !$ignoreMobileImageMaxHeight &&
    css`
      max-height: 62vh;
    `}

  ${media.md`
    max-height: 62vh;
  `}

  ${media.lg`
    height: 100vh;
    max-height: 100vh;
  `}
`;

const Content = styled.section<{ withCard?: boolean }>`
  background-color: ${th.getColor('base000')};
  padding: ${th.getSpace('normal3')} 0 ${pxToRem(52)};

  ${media.lg`
    background-color: transparent;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    padding-bottom: ${th.getSpace('large3')};   
  `}

  ${({ withCard }) =>
    withCard &&
    css`
      background-color: transparent;
      bottom: 0;
      left: 0;
      right: 0;
      padding-bottom: ${th.getSpace('normal2')};
      ${media.lg`
        padding-bottom: ${th.getSpace('large1')};   
        position: absolute;
      `}
    `}
`;

const DesktopImage = styled.div`
  display: none;
  height: 100%;
  position: relative;
  width: 100%;

  ${media.md`
    display: block;
  `}
`;

const MobileImage = styled.div`
  display: block;
  position: relative;
  height: 100%;
  width: 100%;

  ${media.md`
    display: none;
  `}
`;

export default HeroSection;
