import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type MarketingCarouselSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './MarketingCarouselSliceTypes';
import MarketingCarousel from '@yoweb/ui/components/marketing/Carousel/MarketingCarousel';
import { TestimonialCarouselItem } from '@yoweb/ui/components/marketing/TestimonialCarouselItem';
import { SmallPrint } from '@yoweb/ui/components/marketing/SmallPrint';
import { isFieldEmpty } from '@yoweb/prismic-slicemachine/utils/isFieldEmpty';
import { smallPrintSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';
import { Box } from '@yoweb/ui/components/Box';
import { omit } from '@yoweb/utils/omit';

type MarketingCarouselSliceProps = {
  slice: MarketingCarouselSliceResponse;
};

const MarketingCarouselSlice = ({ slice }: MarketingCarouselSliceProps) => {
  const { primary, items } = slice;

  const slides = items.map(({ image, quote, author }, index) => (
    <TestimonialCarouselItem
      // eslint-disable-next-line react/no-array-index-key
      key={`testimonial-carousel-item-${index}`}
      quote={quote}
      image={image.url ?? ''}
      author={author}
      index={index}
    />
  ));

  const renderMarketingCarousel = (children?: JSX.Element) => (
    <Box mx="auto" width={{ lg: '95%' }}>
      <Box backgroundColor="sky" radii={{ lg: 'medium' }}>
        <MarketingCarousel {...omit(primary, ['smallPrint'])} slides={slides} />
      </Box>
      {children}
    </Box>
  );

  if (!isFieldEmpty(primary.smallPrint)) {
    return renderMarketingCarousel(
      <Box px={{ _: 'normal1', lg: 'none' }} mt="normal1">
        <SmallPrint
          align="left"
          noContainer
          text={<PrismicRichText field={primary.smallPrint} components={smallPrintSerializer} />}
        />
      </Box>,
    );
  }

  return renderMarketingCarousel();
};

export default withPrismicGuardian(MarketingCarouselSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
