import styled from 'styled-components';
import Image, { type StaticImageData } from 'next/legacy/image';

import { Title, Text } from '@yoweb/ui/components/typography';
import { Box } from '@yoweb/ui/components/Box';
import { getFontSize, getSpace } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';

export type TestimonialCarouselItemProps = {
  image: StaticImageData | string;
  quote: string;
  author: string;
  index: number;
} & DataTestId;

export const TestimonialCarouselItem = ({
  image,
  quote,
  author,
  index,
}: TestimonialCarouselItemProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minHeight={{ _: 635, md: 620 }}
    backgroundColor="sky"
    pl="normal2"
    pr="normal2"
    data-testid={`testimonial-carousel-item-${index}`}
    width="100%"
  >
    <ImageWrap>
      <Image
        src={image}
        layout="responsive"
        width="120"
        height="120"
        data-testid={`testimonial-carousel-item-image-${index}`}
      />
    </ImageWrap>
    <Quote
      size="xl"
      weight="regular"
      textAlign="center"
      data-testid={`testimonial-carousel-item-quote-${index}`}
    >
      {quote}
    </Quote>
    <Author size="xs" textAlign="center" data-testid={`testimonial-carousel-item-author-${index}`}>
      {author}
    </Author>
  </Box>
);

const ImageWrap = styled.div`
  justify-self: center;
  width: 100%;
  margin-top: ${getSpace('normal1')};
  border-radius: 50%;
  overflow: hidden;
  max-width: ${pxToRem(120)};

  ${media.md`
    margin-top: 0;
    padding: 0;
  `}
`;

const Quote = styled(Text)`
  && {
    margin-top: ${getSpace('normal3')};
    font-size: ${getFontSize('titleSm')};
  }
`;

const Author = styled(Title)`
  && {
    margin-top: ${getSpace('normal3')};
  }
`;
