import styled, { type Colors, css } from 'styled-components';
import Image, { type StaticImageData } from 'next/legacy/image';

import { th } from '@yoweb/ui/styles/utils/theme';
import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { Text } from '@yoweb/ui/components/typography';
import { LineClamp } from '@yoweb/ui/components/LineClamp';

export type CardTitleProps = {
  image: StaticImageData | string;
  imageAltText: string;
  text: string;
};

export const CardTitle = ({ image, imageAltText, text }: CardTitleProps) => (
  <Card>
    <ImageWrapper>
      <Image draggable={false} src={image} layout="fill" objectFit="cover" alt={imageAltText} />
      <TitleWrapper bgColor={'charcoal'}>
        <LineClamp lines={1}>
          <Text style={{ letterSpacing: 'normal' }} size="xl">
            {text}
          </Text>
        </LineClamp>
      </TitleWrapper>
    </ImageWrapper>
  </Card>
);

const fillParent = css`
  position: absolute;
  inset: 0;
`;

const Card = styled.article`
  display: flex;
  flex-direction: column;
  background: ${th.getColor('base200')};
  border-radius: ${th.getRadii('small')};
  height: ${pxToRem(344)};
  position: relative;
  overflow: hidden;
  width: ${pxToRem(248)};

  /**
   * Need to force the card to a new composite layer to ensure correct rendering on Webkit.
   */
  transform: translateZ(0);

  ${media.md`
    height: ${pxToRem(344)};
    width: ${pxToRem(248)};
  `}
`;

const ImageWrapper = styled.div`
  ${fillParent}
  display: flex;
`;

const TitleWrapper = styled.div<{ bgColor: keyof Colors }>`
  display: flex;
  background-color: ${({ bgColor }) => th.getColor(bgColor)};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${pxToRem(69)};
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${th.getColor('base000')};
  padding: ${pxToRem(15)};
`;
