import type { RichTextField, ImageField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const MarketingCarouselSliceType = 'marketing_carousel_slice';

// Required fields in slice
export const requiredPrimaryFields = [] as const; // Array of all required primary fields.
export const requiredItemsFields = ['image', 'quote', 'author'] as const; // Array of all required items fields.

type Primary = {
  loop?: boolean;
  autoPlay?: boolean;
  slideDuration?: number;
  smallPrint?: RichTextField;
};

type Item = {
  image: ImageField;
  quote: string;
  author: string;
  sourceLabel?: string;
  source?: string;
  sourceHref?: string;
};

export type MarketingCarouselSliceResponse = PrismicSlice<
  typeof MarketingCarouselSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>,
  CreateRequiredFields<ArrayToUnion<typeof requiredItemsFields>, Item>
>;
