import { useState, useEffect } from 'react';

export const useViewport = () => {
  const [viewportWidth, setViewportWidth] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  useEffect(() => {
    const resize = () => {
      const newWidth = document.documentElement.clientWidth;
      const newHeight = document.documentElement.clientHeight;

      setViewportWidth(newWidth);
      setViewportHeight(newHeight);
    };

    window.addEventListener('resize', resize);

    resize();
    return () => window.removeEventListener('resize', resize);
  }, []);

  return { viewportWidth, viewportHeight };
};
