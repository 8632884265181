import { useState } from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import type { MouseEvent, ReactNode } from 'react';

import { media, pxToRem } from '@yoweb/ui/styles/utils';
import { th } from '@yoweb/ui/styles/utils/theme';
import { createMixin, type CreateMixinType } from '@yoweb/ui/styles/utils/mixins';
import { Button } from '@yoweb/ui/components/buttons';
import { Title, InteractiveMarketing, Text } from '@yoweb/ui/components/typography';
import { PlayIcon } from '@yoweb/ui/components/Icon/PlayIcon';
import { AnimateWhenInView, STAGGER_DELAY } from '@yoweb/ui/components/AnimateIn';
import VideoModal from '@yoweb/ui/components/Media/VideoModal';
import { Box } from '@yoweb/ui/components/Box';

type Mode = 'dark' | 'light';
export type MaxWidthProps = CreateMixinType<'maxWidth', number>;

export const IDS = {
  BUTTON_CONTAINER: 'hero-copy-btn-container',
  DESCRIPTION: 'hero-copy-description',
  DISCLAIMER: 'hero-copy-disclaimer',
  TITLE: 'hero-copy-title',
};

type HeroCopyProps = {
  title: string;
  description?: string;
  disclaimer?: string | ReactNode;
  primaryBtnLabel?: string | ReactNode;
  primaryBtnUrl?: string;
  secondaryBtnLabel?: string | ReactNode;
  secondaryBtnUrl?: string;
  videoUrl?: string;
  mode?: Mode;
  withCard?: boolean;
  primaryBtnClickHandler?: (e: MouseEvent) => void;
  secondaryBtnClickHandler?: (e: MouseEvent) => void;
} & MaxWidthProps;

// Hero content without card (store homepage)
const HeroContent = ({
  mode,
  title,
  description,
  primaryBtnUrl,
  primaryBtnLabel,
  secondaryBtnUrl,
  secondaryBtnLabel,
  videoUrl,
  disclaimer,
  primaryBtnClickHandler,
  secondaryBtnClickHandler,
}: HeroCopyProps) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <>
      <AnimateWhenInView initialDelay={STAGGER_DELAY} options={{ rootMargin: '-100px 0px' }}>
        <TitleContainer>
          <Title
            data-testid={IDS.TITLE}
            as="h1"
            size={{ _: 'md', lg: 'xl' }}
            variant={{ lg: mode === 'dark' ? 'inverse' : 'primary' }}
          >
            {title}
          </Title>
        </TitleContainer>
        <Title
          data-testid={IDS.DESCRIPTION}
          as="p"
          size={{ _: 'xs', lg: 'sm' }}
          weight="regular"
          variant={{ lg: mode === 'dark' ? 'inverse' : 'primary' }}
        >
          {description}
        </Title>
        <ButtonContainer data-testid={IDS.BUTTON_CONTAINER}>
          {primaryBtnUrl && (
            <Link href={primaryBtnUrl} passHref legacyBehavior>
              <Button
                as="a"
                size={{ _: 'md', lg: 'lg' }}
                onClick={primaryBtnClickHandler}
                data-testid="hero-copy-primary-button"
              >
                {primaryBtnLabel}
              </Button>
            </Link>
          )}
          {primaryBtnClickHandler && !primaryBtnUrl && (
            <Button
              size={{ _: 'md', lg: 'lg' }}
              onClick={primaryBtnClickHandler}
              data-testid="hero-copy-primary-button"
            >
              {primaryBtnLabel}
            </Button>
          )}
          {secondaryBtnUrl && (
            <Link href={secondaryBtnUrl} passHref legacyBehavior>
              <Button
                as="a"
                size={{ _: 'md', lg: 'lg' }}
                onClick={primaryBtnClickHandler}
                data-testid="hero-copy-secondary-button"
              >
                {secondaryBtnLabel}
              </Button>
            </Link>
          )}
          {secondaryBtnClickHandler && !secondaryBtnUrl && (
            <Button
              size={{ _: 'md', lg: 'lg' }}
              onClick={secondaryBtnClickHandler}
              data-testid="hero-copy-primary-button"
              variant="secondary"
            >
              {secondaryBtnLabel}
            </Button>
          )}
          {videoUrl && (
            <Button
              size={{ _: 'md', lg: 'lg' }}
              iconLeft={<StyledPlayIcon />}
              onClick={() => setVideoModalOpen(true)}
              mode={mode}
              data-testid="hero-primary-button"
            >
              {primaryBtnLabel}
            </Button>
          )}
        </ButtonContainer>
        {disclaimer && (
          <Disclaimer data-testid={IDS.DISCLAIMER} size={{ _: 'sm' }} variant="muted">
            {disclaimer}
          </Disclaimer>
        )}
      </AnimateWhenInView>
      {videoUrl && (
        <VideoModal
          videoUrl={videoUrl}
          isOpen={isVideoModalOpen}
          onClose={() => setVideoModalOpen(false)}
        />
      )}
    </>
  );
};

// Hero content with card (pro homepage)
const CardContent = ({
  mode,
  title,
  description,
  primaryBtnUrl,
  primaryBtnLabel,
  secondaryBtnUrl,
  secondaryBtnLabel,
  videoUrl,
  disclaimer,
  primaryBtnClickHandler,
  secondaryBtnClickHandler,
}: HeroCopyProps) => {
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  return (
    <Card
      backgroundColor="base000"
      pt={{ _: 'normal3', lg: 'medium1' }}
      px={{ _: 'normal2', lg: 50 }}
      pb={{ _: 'normal3', lg: 42 }}
      radii="medium"
    >
      <AnimateWhenInView initialDelay={STAGGER_DELAY * 3} options={{ rootMargin: '-100px 0px' }}>
        <TitleContainer withCard>
          <Title
            as="h1"
            data-testid={IDS.TITLE}
            size={{ _: 'md', lg: 'lg' }}
            textAlign={{ _: 'center', lg: 'left' }}
            variant={{ lg: mode === 'dark' ? 'inverse' : 'primary' }}
            whiteSpace="pre-wrap"
          >
            {title}
          </Title>
        </TitleContainer>
        <Text
          data-testid={IDS.DESCRIPTION}
          size="xl"
          variant={{ lg: mode === 'dark' ? 'inverse' : 'primary' }}
          weight="regular"
        >
          {description}
        </Text>
        <ButtonContainer
          data-testid={IDS.BUTTON_CONTAINER}
          withCard
          hasTwoButtons={!!secondaryBtnLabel}
        >
          {primaryBtnUrl && (
            <Link href={primaryBtnUrl} passHref legacyBehavior>
              <Button
                as="a"
                size={{ _: 'md', lg: 'lg' }}
                onClick={primaryBtnClickHandler}
                data-testid="hero-primary-button"
              >
                {primaryBtnLabel}
              </Button>
            </Link>
          )}
          {primaryBtnClickHandler && !primaryBtnUrl && (
            <Button
              size={{ _: 'md', lg: 'lg' }}
              onClick={primaryBtnClickHandler}
              data-testid="hero-primary-button"
            >
              {primaryBtnLabel}
            </Button>
          )}
          {secondaryBtnUrl && (
            <Link href={secondaryBtnUrl} passHref legacyBehavior>
              <Button
                as="a"
                size={{ _: 'md', lg: 'lg' }}
                onClick={secondaryBtnClickHandler}
                data-testid="hero-secondary-button"
                variant="secondary"
              >
                {secondaryBtnLabel}
              </Button>
            </Link>
          )}
          {secondaryBtnClickHandler && !secondaryBtnUrl && (
            <Button
              size={{ _: 'md', lg: 'lg' }}
              onClick={secondaryBtnClickHandler}
              data-testid="hero-secondary-button"
              variant="secondary"
            >
              {secondaryBtnLabel}
            </Button>
          )}
          {videoUrl && (
            <Button
              size={{ _: 'md', lg: 'lg' }}
              iconLeft={<StyledPlayIcon />}
              onClick={() => setVideoModalOpen(true)}
              mode={mode}
              data-testid="hero-primary-button"
            >
              {primaryBtnLabel}
            </Button>
          )}
        </ButtonContainer>
        {disclaimer && (
          <Disclaimer data-testid={IDS.DISCLAIMER} size={{ _: 'sm' }} variant="muted">
            {disclaimer}
          </Disclaimer>
        )}
      </AnimateWhenInView>
      {videoUrl && (
        <VideoModal
          videoUrl={videoUrl}
          isOpen={isVideoModalOpen}
          onClose={() => setVideoModalOpen(false)}
        />
      )}
    </Card>
  );
};

/**
 * Hero copy of the home page.
 */
const HeroCopy = ({ withCard, maxWidth = { lg: 410 }, ...rest }: HeroCopyProps) => (
  <AnimateWhenInView initialDelay={STAGGER_DELAY} options={{ rootMargin: '-100px 0px' }}>
    <Content maxWidth={maxWidth} withCard={withCard}>
      {withCard ? <CardContent {...rest} /> : <HeroContent {...rest} />}
    </Content>
  </AnimateWhenInView>
);

export const maxWidthMixin = createMixin('maxWidth')<number, Pick<HeroCopyProps, 'maxWidth'>>(
  ({ maxWidth }, css) =>
    maxWidth
      ? css`
          max-width: ${pxToRem(maxWidth)};
        `
      : css``,
);

const Content = styled.div<Pick<HeroCopyProps, 'maxWidth'> & { withCard?: boolean }>`
  ${maxWidthMixin}

  width: 100%;
  margin: 0 auto;
  text-align: center;

  ${media.lg`
    margin: 0;
    text-align: left;
  `}

  ${({ withCard }) =>
    withCard &&
    css`
      text-align: center;
      ${media.lg`
        margin: 0;
        text-align: left;
      `}
    `}
`;

const TitleContainer = styled.div<{ withCard?: boolean }>`
  margin: 0 auto ${th.getSpace('normal1')} auto;

  ${({ withCard }) =>
    withCard &&
    css`
      text-align: center;
      margin: 0;
      margin-bottom: ${th.getSpace('normal1')};
    `}
`;

const ButtonContainer = styled.div<{ withCard?: boolean; hasTwoButtons?: boolean }>`
  display: flex;
  justify-content: ${(hasTwoButtons) => (hasTwoButtons ? 'center' : 'flex-start')};
  margin-top: ${th.getSpace('normal2')};
  grid-gap: ${th.getSpace('small3')};

  ${media.lg`
    margin-top: ${(withCard) => (withCard ? th.getSpace('normal2') : th.getSpace('medium1'))};
    justify-content: flex-start;
  `}
`;

const StyledPlayIcon = styled(PlayIcon)`
  && {
    width: ${pxToRem(12)};
    height: ${pxToRem(12)};
  }
`;

const Card = styled(Box)`
  && {
    display: inline-block;

    ${media.lg`
      margin-left: -${th.getSpace('normal2')};
    `}
  }
`;

const Disclaimer = styled(InteractiveMarketing)`
  && {
    display: block;
    margin-top: ${th.getSpace('small3')};

    ${media.lg`
      margin-top: ${th.getSpace('normal1')};
    `}
  }
`;
export default HeroCopy;
