import styled, { css } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';

import { th } from '@yoweb/ui/styles/utils/theme';
import { CardReveal } from '@yoweb/ui/components/marketing/DreamGallery/CardReveal';
import {
  Direction,
  type ActiveIndex,
  type Card,
} from '@yoweb/ui/components/marketing/DreamGallery/DreamGallery.utils';
import { CardTitle } from '@yoweb/ui/components/marketing/DreamGallery/CardTitle';

const ANIMATION_VELOCITY = 30;

type DreamGalleryLaneProps = {
  direction: Direction;
  items: Card[];
  active: ActiveIndex;
  isInView: boolean;
  row: number;
  setActive(key: ActiveIndex): void;
  displayTextAsTitle?: boolean;
};

export const DreamGalleryLane = ({
  items,
  direction,
  active,
  row,
  isInView,
  setActive,
  displayTextAsTitle,
}: DreamGalleryLaneProps) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const animation = useAnimation();
  const [width, setWidth] = useState(-1);
  const constraints = {
    left: -width / 2,
    right: width / 2,
  };

  const startAnimation = useCallback(() => {
    animation.start({
      x: (width * (direction === Direction.LeftToRight ? -1 : 1)) / 2,
      transition: {
        ease: [0.25, 0.2, 0.2, 1],
        duration: width / ANIMATION_VELOCITY,
      },
    });
    setActive(null);
  }, [width, animation, setActive, direction]);

  const onResize = () => {
    if (!contentRef.current || !parentRef.current) {
      return;
    }

    const parentWidth = parentRef.current.offsetWidth;
    let sum = 0;

    Array.from(contentRef.current.childNodes).forEach((node) => {
      if (node instanceof HTMLElement) {
        sum += node.clientWidth;
      }
    });

    setWidth(Math.max(sum - parentWidth, 0));
  };

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize, { passive: true });

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (width < 0 || !isInView) {
      return;
    }

    startAnimation();
  }, [startAnimation, width, isInView]);

  return (
    <DragContainer ref={parentRef}>
      <Draggable
        drag={width > 0 ? 'x' : undefined}
        animate={animation}
        dragConstraints={constraints}
      >
        <CardRow
          role="listbox"
          tabIndex={0}
          ref={contentRef}
          isReversed={direction === Direction.RightToLeft}
          onMouseEnter={() => {
            animation.stop();
          }}
          onMouseLeave={startAnimation}
        >
          {!displayTextAsTitle &&
            items.map((item, index) => {
              const id = [row, index].join();
              const isActive = active === id;
              return (
                <CardWrap
                  role="option"
                  tabIndex={0}
                  aria-selected={isActive}
                  key={index}
                  onMouseEnter={() => {
                    setActive(id);
                  }}
                  onTouchStart={() => {
                    if (isActive) {
                      startAnimation();
                    } else {
                      setActive(id);
                    }
                  }}
                >
                  <CardReveal {...item} isRevealed={isActive} />
                </CardWrap>
              );
            })}
          {displayTextAsTitle &&
            items.map((item, index) => {
              const id = [row, index].join();
              const isActive = active === id;
              return (
                <CardWrap
                  role="option"
                  tabIndex={0}
                  aria-selected={isActive}
                  key={index}
                  onMouseEnter={() => {
                    setActive(id);
                  }}
                  onTouchStart={() => {
                    if (isActive) {
                      startAnimation();
                    } else {
                      setActive(id);
                    }
                  }}
                >
                  <CardTitle {...item} />
                </CardWrap>
              );
            })}
        </CardRow>
      </Draggable>
    </DragContainer>
  );
};

const DragContainer = styled(motion.div).attrs({
  whileDrag: { cursor: 'grabbing' },
})`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const Draggable = styled(motion.div)`
  transform: translateZ(0);
`;

const CardRow = styled.div<{ isReversed: boolean }>`
  display: flex;
  min-width: 100%;

  ${({ isReversed }) =>
    isReversed &&
    css`
      flex-direction: row-reverse;
    `}
`;
const CardWrap = styled.div`
  padding: ${th.getSpace('small2')};
`;
